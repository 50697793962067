export const importPaths = {
    "": async () => await import(`./content/home.js`),
    "lean/five-s": async () => await import(`./content/lean/five-s.js`),
    "lean/andon": async () => await import(`./content/lean/andon.js`),
    "architecture": async () => await import(`./content/architecture.js`),
    "architecture/systems-architecture": async () => await import(`./content/architecture/systems-architecture.js`),
    "architecture/network-interactivity": async () => await import(`./content/architecture/network-interactivity.js`),
    "lean/gemba": async () => await import(`./content/lean/gemba.js`),
    "techniques/non-functional-requirements": async () => await import(`./content/techniques/non-functional-requirements.js`),
    "privacy": async () => await import(`./content/privacy.js`),
    "cookies": async () => await import(`./content/cookies.js`),
    "techniques/theory-of-constraints": async () => await import(`./content/techniques/theory-of-constraints.js`),
    "techniques/requirement-conversion": async () => await import(`./content/techniques/requirement-conversion.js`),
    "architecture/ipo-paradigm": async () => await import(`./content/architecture/ipo-paradigm.js`),
    "techniques/software-analysis": async () => await import(`./content/techniques/software-analysis.js`),
    "lean/kanban-intro": async () => await import(`./content/lean/kanban-intro.js`),
    "lean/limiting-wip": async () => await import(`./content/lean/limiting-wip.js`),
    "lean/kanban-history": async () => await import(`./content/lean/kanban-history.js`),
    "lean/lean-software-development": async () => await import(`./content/lean/lean-software-development.js`),
    "lean/poka-yoke": async () => await import(`./content/lean/poka-yoke.js`),
    "lean/kaizen": async () => await import(`./content/lean/kaizen.js`),
    "news": async () => await import(`./content/news.js`),
    "news/tech": async () => await import(`./content/news/tech.js`),
    "news/science": async () => await import(`./content/news/science.js`),
    "news/business": async () => await import(`./content/news/business.js`),
    "lean": async () => await import(`./content/lean.js`),
    "techniques": async () => await import(`./content/techniques.js`),
    "techniques/agile-intro": async () => await import(`./content/techniques/agile-intro.js`),
};
