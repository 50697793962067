const { Partials } = require('./helpers/partialHelpers.js');
const { PageRender } = require('./helpers/renderHelpers.js');
const { paths } = require('./paths.js');

/*
const { initializeApp } = require("firebase/app");
const { CookieBanner } = require('./partials.js');

const firebaseConfig = {
    apiKey: "AIzaSyDqa2KKmN7F-NOkFrwYOHw2q5gj_7rt0Bw",
    authDomain: "magicframework-596c1.firebaseapp.com",
    projectId: "magicframework-596c1",
    storageBucket: "magicframework-596c1.appspot.com",
    messagingSenderId: "442217066130",
    appId: "1:442217066130:web:248c2fc727de60d6e5c04b",
    measurementId: "G-959VNW7KCK"
};
*/


PageRender.handleRoutingPageRender()
Partials.createNavigation(paths, `AgileCats: A Software Delivery Cookbook`)
//Partials.handleCookieConsent(initializeApp(firebaseConfig), CookieBanner);

